<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
        <div class="classObj"><el-button @click="addfreight" type="primary" icon="el-icon-plus">新增运费模板</el-button></div>
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="100px">
            <el-form-item label="快速查找：" style="margin-bottom: 0">
              <el-input v-model="searchForm.name" clearable  placeholder="请输入运费模板名称" style="width:250px"></el-input>
            </el-form-item>
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
          </el-form>
      </div>

    <div class="mainbox">
       <div class="tableItem" v-for="(item, index) in tableData">
         <div class="tableTitle">
            <div class="spanInfo">
              <span>最后编辑时间：{{item.createTime}}</span>
              <el-button @click="copyClick(item)" type="text" size="small">复制模板</el-button>
              <el-button type="text" size="small"  @click="editClick(item)">编辑</el-button>
              <el-popconfirm @confirm="delClick(item)" title="删除此模板也会同步取消对应商品的运费模板，确定要删除吗？">
                <template #reference>
                  <el-button type="text" size="small">删除</el-button>
                </template>
            </el-popconfirm>
            </div>
           <strong>{{item.name}} <em v-if="item.packageFree == 1">包邮</em></strong>
         </div>
         <div v-if="item.packageFree == 0">
       <div class="yunfeiItem">
          <strong>默认：</strong>
          <el-input v-model="item.rule.defaultItem.firstNum" disabled style="width:100px" placeholder="请输入"></el-input> 件内，
          <el-input v-model="item.rule.defaultItem.firstMoney" disabled  style="width:100px" placeholder="请输入"></el-input> 元。
          每增加 <el-input v-model="item.rule.defaultItem.secondNum" disabled  style="width:100px" placeholder="请输入"></el-input> 件内，
          增加运费  <el-input v-model="item.rule.defaultItem.secondMoney" disabled  style="width:100px" placeholder="请输入"></el-input> 元。
      </div>  
       <el-table
        :data="item.rule.customItems"
        border
        @selection-change="handleSelectionChange"
        :header-cell-style="{background:'#f7f7f7', color:'#666'}"
        style="width: 100%" v-if="item.rule.customItems && item.rule.customItems.length > 0">
        <el-table-column
          label="运送到"
          >
          <template slot-scope="scope">
            <span v-for="itemss in scope.row.provinces">
             {{itemss.province}},
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="firstNum"
          label="首件(个)"
          width="100" header-align="center" align="center">
        </el-table-column>
          <el-table-column
          prop="firstMoney"
          label="运费(元)" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="secondNum"
          label="续件(个)" width="100" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="secondMoney"
          label="运费(元)" width="100" header-align="center" align="center">
        </el-table-column>
      </el-table>
       <el-table
        :data="item.packageFreeRule.rules"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}"
        style="width: 100%" v-if="item.packageFreeRule.rules.length>0">
        <el-table-column
          label="为指定地区设置包邮: 运送到"
          >
          <template slot-scope="scope">
            <span v-for="itemss in scope.row.provinces">
             {{itemss.province}},
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="包邮条件" width="400" header-align="center" align="center">
           <template slot-scope="scope">
            <span style="color: #ff7900; font-weight: bold;"> 满 {{scope.row.num}} 件，{{scope.row.money}}元以上包邮</span>
           </template>
        </el-table-column>
        
      </el-table>
       </div>
       </div>

      <div class="paginations">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="changePage" :current-page="page" :page-size="pageSize"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeliveryList, deliveryDel, deliveryCopy} from "@/api/moban";
import { MessageBox } from 'element-ui'
import passWord from "@/components/password";
let moment = require('moment');
export default {
  name: 'Orderss',
  components: { passWord },
  data() {
    return {
      searchForm: {
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    addfreight() {
       this.$router.push({
         path: '/freight/detail'
       })
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.role = window.sessionStorage.getItem("role")
      datas.orderByCreated = 'DESC'
      getDeliveryList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          // nodataArr.map((item, i) => {
          //   if(item.rule.customItems && item.rule.customItems.length > 0) {
          //     item.rule.customItems.map((items)=>{
          //       items.provinces = that.uniqueFun(items.cities, 'province')
          //     })
          //     item.packageFreeRule.rules.map((itemss)=>{
          //       itemss.provinces = that.uniqueFun(itemss.cities, 'province')
          //     })
          //   }
          // })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleClose(){
      this.kuaiDiflag = false;
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    delClick(row){
      var that = this
      var datas = {
        id: row.id
      }
      deliveryDel(datas).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: '删除成功',
            type: 'success'
          })
          that.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    copyClick(row){
      var that = this
      var datas = {
        id: row.id
      }
      deliveryCopy(datas).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: '复制成功',
            type: 'success'
          })
          that.getTopSearch()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
    editClick(row){
      this.$router.push({
        path: '/freight/detail?id=' + row.id
      })
    },
    handleCurrentChange(){
      
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.yunfeiItem{
  display: block;
  overflow: hidden;
  padding: 10px 20px;
  background-color: #fff;
  font-weight: bold;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  font-size: 12px;
}
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.tableItem{
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}
.tableTitle{
  height: 44px;
  background-color: #e6f1fc;
  line-height: 44px;
  padding: 0 20px;
}
.tableTitle strong{
  font-size: 15px; color: #409EFF;
}
.tableTitle strong em{
  font-style: normal;
  color: #fff;
  background-color: #ff7900;
  padding: 4px 10px;
  border-radius: 2px;
  font-size: 12px;
  margin-right: 5px;

}
.tableTitle .spanInfo{
  float: right;
  font-size: 12px;
  display: block;
  color: #999;
}
.tableTitle span{
  padding: 0 10px;
  color: #409EFF;
}
.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
</style>